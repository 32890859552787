import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {
	NbActionsModule,
	NbLayoutModule,
	NbMenuModule,
	NbSidebarModule,
	NbUserModule,
	NbContextMenuModule,
	NbButtonModule,
	NbSelectModule,
	NbThemeModule,
} from '@nebular/theme';
import { FooterComponent, HeaderComponent } from './components';
import {
	CapitalizePipe,
	PluralPipe,
	RoundPipe,
	TimingPipe,
	NumberWithCommasPipe,
} from './pipes';
import { OneColumnLayoutComponent } from './layouts';
import { MATERIAL_LIGHT_THEME } from './styles/material/theme.material-light';
import { MATERIAL_DARK_THEME } from './styles/material/theme.material-dark';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { CloudAssetModule } from '@shared/directives/cloud-asset/cloud-asset.module';
import { TzDateModule } from '@shared/pipes/tz-date/tz-date.module';

const NB_MODULES = [
	CommonModule,
	MatRippleModule,
	MatSlideToggleModule,
	NbLayoutModule,
	NbMenuModule,
	NbUserModule,
	NbActionsModule,
	NbSidebarModule,
	NbContextMenuModule,
	NbButtonModule,
	NbSelectModule,
];

const COMPONENTS = [HeaderComponent, FooterComponent, OneColumnLayoutComponent];

const PIPES = [
	CapitalizePipe,
	PluralPipe,
	RoundPipe,
	TimingPipe,
	NumberWithCommasPipe,
];

@NgModule({
	imports: [
		...NB_MODULES,
		MatIconModule,
		MatButtonModule,
		MatMenuModule,
		RouterModule,
		TzDateModule,
		CloudAssetModule,
	],
	exports: [CommonModule, MatRippleModule, ...PIPES, ...COMPONENTS],
	declarations: [...COMPONENTS, ...PIPES],
})
export class ThemeModule {
	static forRoot(): ModuleWithProviders<ThemeModule> {
		return {
			ngModule: ThemeModule,
			providers: [
				...NbThemeModule.forRoot(
					{
						name: 'default',
					},
					[MATERIAL_LIGHT_THEME, MATERIAL_DARK_THEME],
				).providers,
			],
		};
	}
}
