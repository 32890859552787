/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import {
	HttpInterceptor,
	HttpHandler,
	HttpRequest,
	HttpErrorResponse,
} from '@angular/common/http';
import { AuthService } from '@core/services/auth.service';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import showErrorSnackBar from '@core/utils/errorSnackBar';

export const skipErrorInterceptorHeader = 'X-Skip-Error-Interceptor';

@Injectable()
export class APIErrorInterceptor implements HttpInterceptor {
	constructor(
		private _authService: AuthService,
		private _router: Router,
		private _snackBar: MatSnackBar,
	) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
		if (req.headers.get(skipErrorInterceptorHeader)) {
			return next.handle(req);
		}

		return next.handle(req).pipe(
			catchError((error) => {
				if (error instanceof HttpErrorResponse) {
					if (error.status === 401) {
						return this._authService.signOut(
							this._router.routerState.snapshot.url,
						);
					} else if (error.status === 429) {
						showErrorSnackBar(this._snackBar, error.error);
					} else if (error.status === 403) {
						this._router.navigate(['/code/403'], {
							state: {
								requestedUrl: req.url,
								message: error.error.message,
							},
						});
					} else if (error.status === 404) {
						this._router.navigate(['/code/404'], {
							state: {
								requestedUrl: req.url,
							},
						});

						showErrorSnackBar(this._snackBar, error.error.message);
					} else {
						showErrorSnackBar(this._snackBar, error.error.message);
					}
				}

				// eslint-disable-next-line @typescript-eslint/no-unsafe-return
				return throwError(() => error);
			}),
		);
	}
}
