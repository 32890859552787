import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Organization } from '@core/models/entities/Organization';
import { OrganizationRegistration } from '@core/models/entities/Organization-Registration';
import { OrganizationType } from '@core/models/entities/Organization-Type';
import { Permission } from '@core/models/entities/Permission';
import { User, UserOrganization } from '@core/models/entities/User';
import { BaseHttpResponse } from '@core/models/interfaces/BaseHttpResponse.interface';
import { BehaviorSubject, concatMap, Observable, Subject, tap } from 'rxjs';
import { AcceptUserInviteData } from './invite.service';
import { Permission as PermissionEnum } from '@core/models/enums/permissions.enum';
import BaseService from '@core/models/base/base.service';
import {
	DashboardSetting,
	DashboardWidgets,
} from '@core/models/entities/Dashboard-Setting';
import * as Sentry from '@sentry/angular';
import { skipErrorInterceptorHeader } from '@core/interceptors/api-error.interceptor';

export enum OrganizationTypes {
	STUDIO = 'Studio',
	OPERATOR = 'Operator',
	FIREBALL = 'Fireball',
}

export interface UserOrganizationsResponse {
	organizations: UserOrganization[];
	selectedOrganization: Organization;
	user: Partial<User>;
}

export interface ConfirmOrganizationRegistrationData
	extends AcceptUserInviteData {
	organizationLegalName: string;
}

export interface UpdateDashboardSettingBody {
	widget: DashboardWidgets;
	value: boolean;
}

@Injectable({
	providedIn: 'root',
})
export class OrganizationService extends BaseService<Organization> {
	constructor(_http: HttpClient) {
		super(_http, 'organizations');

		this.reloadOrganizations$
			.pipe(concatMap(() => this.findUserOrganizations()))
			.subscribe();

		this.userOrganizationChanged$.subscribe((org) => {
			Sentry.setUser({
				...this.user,
				selectedOrganization: org,
			});
		});
	}

	readonly userOrganizationChanged$ = new BehaviorSubject<Organization>(null);

	readonly reloadOrganizations$ = new Subject<void>();

	userOrganizations: UserOrganization[];

	user: Partial<User>;

	get dashboardSetting(): DashboardSetting {
		return this.userOrganizations?.find(
			(o) => o.organization.id === this.selectedOrganization?.id,
		).dashboardSetting;
	}

	set dashboardSetting(setting: DashboardSetting) {
		this.userOrganizations.find(
			(o) => o.organization.id === this.selectedOrganization?.id,
		).dashboardSetting = setting;
	}

	get selectedOrganization(): Organization {
		return this.userOrganizationChanged$.value;
	}

	get fireballSelected(): boolean {
		return this.selectedOrganization?.name === OrganizationTypes.FIREBALL;
	}

	get userPermissions(): Permission[] {
		return this.userOrganizations
			?.find((o) => o.organization.id === this.selectedOrganization.id)
			.roles.reduce<Permission[]>(
				(total, role) => total.concat(role.permissions),
				[],
			);
	}

	updateDashboardSetting(
		data: UpdateDashboardSettingBody,
	): Observable<BaseHttpResponse<DashboardSetting>> {
		return this._http.patch<BaseHttpResponse<DashboardSetting>>(
			'organizations/dashboard',
			data,
		);
	}

	hasPermission(permission: PermissionEnum): boolean {
		return this.userPermissions?.some((p) => p.name === permission);
	}

	findSelected(): Observable<BaseHttpResponse<Organization>> {
		return this._http.get<BaseHttpResponse<Organization>>(
			'organizations/selected',
		);
	}

	findUserOrganizations(
		skipErrorInterceptor = false,
	): Observable<BaseHttpResponse<UserOrganizationsResponse>> {
		let headers = new HttpHeaders();

		if (skipErrorInterceptor) {
			headers = headers.append(skipErrorInterceptorHeader, 'true');
		}

		return this._http
			.get<BaseHttpResponse<UserOrganizationsResponse>>('organizations', {
				headers,
			})
			.pipe(tap(({ data }) => this._setOrganizationData(data)));
	}

	select(organizationId: string): Observable<BaseHttpResponse<string>> {
		return this._http.patch<BaseHttpResponse<string>>(
			`organizations/${organizationId}`,
			null,
		);
	}

	findTypes(): Observable<BaseHttpResponse<OrganizationType[]>> {
		return this._http.get<BaseHttpResponse<OrganizationType[]>>(
			'organizations/types',
		);
	}

	updateOne(
		data: Partial<Organization>,
	): Observable<BaseHttpResponse<Organization>> {
		return this._http.put<BaseHttpResponse<Organization>>(
			'organizations',
			data,
		);
	}

	findOrganizationRegistration(
		registrationId: string,
	): Observable<BaseHttpResponse<OrganizationRegistration>> {
		return this._http.get<BaseHttpResponse<OrganizationRegistration>>(
			`organizations/registration/${registrationId}`,
		);
	}

	/** This will actually login the user by returning proper cookie */
	confirmOrganizationRegistration(
		registrationId: string,
		data: ConfirmOrganizationRegistrationData,
	): Observable<BaseHttpResponse<string>> {
		return this._http.patch<BaseHttpResponse<string>>(
			`organizations/registration/${registrationId}`,
			data,
		);
	}

	private _setOrganizationData({
		organizations,
		selectedOrganization,
		user,
	}: UserOrganizationsResponse): void {
		this.userOrganizations = organizations.sort((a, b) =>
			a.organization.organizationTypeName === OrganizationTypes.FIREBALL
				? -1
				: a.organization.name > b.organization.name
				? 1
				: -1,
		);

		// Force refresh user profile pic by adding current timestamp
		this.user = {
			...user,
			profilePicture: user.profilePicture
				? `${user.profilePicture}?${new Date().getTime()}`
				: null,
		};

		this.userOrganizationChanged$.next({
			...selectedOrganization,
			logo: selectedOrganization.logo
				? `${selectedOrganization.logo}?${new Date().getTime()}`
				: null,
		});

		Sentry.setUser({
			...user,
			selectedOrganization,
		});
	}
}
