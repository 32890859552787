import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseHttpResponse } from '../interfaces/BaseHttpResponse.interface';
import parseParams from '@core/utils/parseParams';
import { Params } from '@angular/router';

export interface BaseHttpFilters extends Params {
	page?: number | string;
	size?: number;
}

@Injectable({
	providedIn: 'root',
})
/**
 * Base entity service used to communicate with the API
 * @param E Entity inteface from @core/models
 * @param M Mutate data interface - props needed to edit/create entity
 */
export default abstract class BaseService<E, M = Partial<E>> {
	constructor(public _http: HttpClient, private _endpoint: string) {}

	/**
	 * Gets all entities by page number(starting from 0) and page size
	 * @param filters Query param filters
	 */
	findAll(filters?: BaseHttpFilters): Observable<BaseHttpResponse<E[]>> {
		const params = parseParams(filters);

		return this._http.get<BaseHttpResponse<E[]>>(this._endpoint, {
			params,
		});
	}

	/**
	 * Gets all entities for selector components without permission needed
	 * @param filters Query param filters
	 */
	findAllForSelect(
		filters?: BaseHttpFilters,
	): Observable<BaseHttpResponse<E[]>> {
		const params = parseParams(filters);

		return this._http.get<BaseHttpResponse<E[]>>(`${this._endpoint}/select`, {
			params,
		});
	}

	/**
	 * Gets single entity by id
	 * @param id Id of the entity
	 */
	findOne(id: string): Observable<BaseHttpResponse<E>> {
		return this._http.get<BaseHttpResponse<E>>(`${this._endpoint}/${id}`);
	}

	/**
	 * Creates an entity
	 * @param data Item mutate data we send to the API to create an entity
	 */
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	create(data: M, _entityId?: string): Observable<BaseHttpResponse<E>> {
		return this._http.post<BaseHttpResponse<E>>(`${this._endpoint}`, data);
	}

	/**
	 *
	 * @param data Item mutate data we send to the API to edit an entity
	 * @param id Id of the entity
	 */
	update(data: M, id: string): Observable<BaseHttpResponse<E>> {
		return this._http.put<BaseHttpResponse<E>>(`${this._endpoint}/${id}`, data);
	}

	/**
	 * Activates entity
	 * @param entityId Id of the entity
	 */
	activate(entityId: string): Observable<BaseHttpResponse<E>> {
		return this._http.patch<BaseHttpResponse<E>>(
			`${this._endpoint}/${entityId}/activate`,
			null,
		);
	}

	/**
	 * Deactivates entity
	 * @param entityId Id of the entity
	 */
	deactivate(entityId: string): Observable<BaseHttpResponse<E>> {
		return this._http.patch<BaseHttpResponse<E>>(
			`${this._endpoint}/${entityId}/deactivate`,
			null,
		);
	}

	/**
	 * Deletes single entity by id
	 * @param entityId Id of the entity
	 */
	delete(entityId: string): Observable<BaseHttpResponse<string>> {
		return this._http.delete<BaseHttpResponse<string>>(
			`${this._endpoint}/${entityId}`,
		);
	}

	/**
	 * Deletes multiple entities at once
	 * @param ids Strings array of entity ids to delete
	 */
	deleteBulk(ids: string[]): Observable<BaseHttpResponse<string>> {
		const params = new HttpParams().set('ids', JSON.stringify(ids));

		return this._http.delete<BaseHttpResponse<string>>(
			`${this._endpoint}/delete-bulk`,
			{
				params,
			},
		);
	}
}
