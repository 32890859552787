import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { environment } from '@environment/environment';

@Directive({
	selector: '[fireCloudAsset]',
})
export class CloudAssetDirective {
	@Input()
	set src(src: string) {
		this._renderer.setAttribute(
			this._el.nativeElement,
			'src',
			environment.assetsUrl + src,
		);
	}

	constructor(
		private _el: ElementRef<HTMLImageElement>,
		private _renderer: Renderer2,
	) {}
}
