import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
import * as advancedFormat from 'dayjs/plugin/advancedFormat';
import { OrganizationService } from '@core/services/organization.service';

dayjs.extend(timezone);

dayjs.extend(utc);

dayjs.extend(advancedFormat);

@Pipe({
	name: 'tzDate',
})
export class TzDatePipe implements PipeTransform {
	constructor(private _orgService: OrganizationService) {}

	transform(
		value: string | number | Date | dayjs.Dayjs,
		format = 'MMM D, YYYY HH:mm:ss',
		timezone?: string,
	): string {
		if (!value) {
			return;
		}

		// undefined will return dates in user's timezone
		const date = dayjs(value)
			.clone()
			.tz(timezone ?? this._orgService.user?.timezoneName ?? undefined);

		return date.format(format ?? 'MMM D, YYYY HH:mm:ss');
	}
}
