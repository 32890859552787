import { MatSnackBar } from '@angular/material/snack-bar';

const showErrorSnackBar = (
	snackBar: MatSnackBar,
	errorMessage: string,
): void => {
	const dialogRef = snackBar.open(errorMessage, 'Dismiss', {
		panelClass: 'error-snackbar',
	});

	dialogRef.onAction().subscribe(() => dialogRef.dismiss());
};

export default showErrorSnackBar;
