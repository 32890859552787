import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';

const sentryHandler = Sentry.createErrorHandler({
	showDialog: false,
});

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
	handleError(error: Error): void {
		const chunkFailedMessage = /Loading chunk [\d]+ failed/;

		if (chunkFailedMessage.test(error.message)) {
			alert('New version available, your browser will now reload');

			return window.location.reload();
		}

		sentryHandler.handleError(error);
	}
}
