import { Overlay } from '@angular/cdk/overlay';
import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import {
	NbMediaBreakpointsService,
	NbMenuService,
	NbSidebarService,
	NbThemeService,
} from '@nebular/theme';
import { map, concatMap, of } from 'rxjs';

@Component({
	selector: 'fire-one-column-layout',
	styleUrls: ['./one-column.layout.scss'],
	templateUrl: './one-column.layout.html',
})
export class OneColumnLayoutComponent implements AfterViewInit, OnDestroy {
	private _observer: MutationObserver;

	constructor(
		private _sidebarService: NbSidebarService,
		private _menuService: NbMenuService,
		private _themeService: NbThemeService,
		private _breakpointService: NbMediaBreakpointsService,
		private _overlay: Overlay,
	) {
		const { md } = this._breakpointService.getBreakpointsMap();

		// Whenever we're below the md breakpoint close the sidemenu whenever a menu item is clicked
		this._themeService
			.onMediaQueryChange()
			.pipe(
				map(([, currentBreakpoint]) => currentBreakpoint.width <= md),
				concatMap((isMd) =>
					isMd ? this._menuService.onItemClick() : of(null),
				),
			)
			.subscribe((item) => {
				if (item && item.tag === 'sidebar-menu') {
					this._sidebarService.collapse('menu-sidebar');
				}
			});
	}

	ngAfterViewInit(): void {
		this._overlay.create();

		const overlayContainer = document.getElementsByClassName(
			'cdk-overlay-container',
		);

		this._observer = new MutationObserver((mutations) => {
			mutations.forEach((mutation) => {
				mutation.addedNodes.forEach((node: HTMLElement) => {
					if (node.classList?.contains('mat-select-panel-wrap')) {
						(
							node.firstChild as HTMLElement
						).style.maxHeight = `calc(100vh - 3px - ${node.parentElement.style.top})`;
					}
				});
			});
		});

		this._observer.observe(overlayContainer[0], {
			subtree: true,
			childList: true,
		});
	}

	ngOnDestroy(): void {
		this._observer.disconnect();
	}
}
