<nb-layout>
	<nb-layout-header fixed>
		<fire-header></fire-header>
	</nb-layout-header>

	<nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>
		<ng-content select="nb-menu"></ng-content>
	</nb-sidebar>

	<nb-layout-column>
		<ng-content select="router-outlet"></ng-content>
	</nb-layout-column>

	<nb-layout-footer fixed>
		<fire-footer></fire-footer>
	</nb-layout-footer>
</nb-layout>
