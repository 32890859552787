/* eslint-disable @typescript-eslint/no-unsafe-return */
import { DefaultValueAccessor } from '@angular/forms';

const original = DefaultValueAccessor.prototype.registerOnChange;

DefaultValueAccessor.prototype.registerOnChange = function (fn): void {
	return original.call(this, (value: unknown) => {
		const trimmed = typeof value === 'string' ? value.trim() : value;

		return fn(trimmed);
	});
};
