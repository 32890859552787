<mat-form-field [formGroup]="fGroup" class="w-100">
	<mat-label>{{label}}</mat-label>

	<mat-select [formControlName]="fControlName" [placeholder]="label">
		<mat-option>
			<ngx-mat-select-search
				placeholderLabel="Search"
				noEntriesFoundLabel="No timezone found"
				[formControl]="searchTerm"
			>
				<mat-icon ngxMatSelectSearchClear>close</mat-icon>
			</ngx-mat-select-search>
		</mat-option>

		<mat-option *ngIf="!hasRequiredValidator" [value]="null"
			><i>None</i></mat-option
		>

		<mat-option
			*ngFor="let timezone of filteredTimezones"
			[value]="timezone.name"
		>
			{{getDisplayName(timezone.name)}} (UTC{{timezone.offset}})
		</mat-option>
	</mat-select>

	<button
		*ngIf="showDetect"
		mat-button
		matSuffix
		(click)="detectTimezone($event)"
	>
		Detect
	</button>

	<mat-error *ngIf="fGroup?.get(fControlName)?.hasError('required')">
		Please select a timezone
	</mat-error>
</mat-form-field>
