import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TzDatePipe } from './tz-date.pipe';

@NgModule({
	declarations: [TzDatePipe],
	exports: [TzDatePipe],
	imports: [CommonModule],
})
export class TzDateModule {}
