import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { User } from '@core/models/entities/User';
import { BaseHttpResponse } from '@core/models/interfaces/BaseHttpResponse.interface';
import { ChangePasswordData } from '@shared/components/forms/change-password-form/change-password-form.component';
import { firstValueFrom, Observable } from 'rxjs';
import * as Sentry from '@sentry/angular';
import { OrganizationService } from './organization.service';

export interface JwtTokenPayload {
	id: string;
	name: string;
	exp: number;
	iat: number;
}

export interface SignInData {
	email: string;
	password: string;
	rememberMe: boolean;
}

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	constructor(
		private _http: HttpClient,
		private _router: Router,
		private _orgService: OrganizationService,
	) {}

	signIn(body: SignInData): Observable<unknown> {
		return this._http.post<unknown>('auth/sign-in', body);
	}

	profile(): Observable<BaseHttpResponse<User>> {
		return this._http.get<BaseHttpResponse<User>>('auth/profile');
	}

	editProfile(data: Partial<User>): Observable<BaseHttpResponse<User>> {
		return this._http.put<BaseHttpResponse<User>>('auth/profile', data);
	}

	changePassword(
		data: ChangePasswordData,
	): Observable<BaseHttpResponse<string>> {
		return this._http.post<BaseHttpResponse<string>>(
			'auth/change-password',
			data,
		);
	}

	async signOut(
		returnUrl?: string,
		navigateTo = 'auth/sign-in',
	): Promise<boolean> {
		await firstValueFrom(this._http.get('auth/sign-out'));

		const extras = {
			queryParams: {},
		} as NavigationExtras;

		if (returnUrl) {
			extras.queryParams.returnUrl = returnUrl;
		}

		Sentry.setUser(null);

		this._orgService.user = null;

		this._orgService.userOrganizations = null;

		if (navigateTo) {
			return await this._router.navigate([navigateTo], extras);
		}
	}

	forgottenPassword(email: string): Observable<BaseHttpResponse<string>> {
		return this._http.post<BaseHttpResponse<string>>(
			'auth/forgotten-password',
			{
				email,
			},
		);
	}

	resetPassword(
		data: ChangePasswordData,
	): Observable<BaseHttpResponse<string>> {
		return this._http.post<BaseHttpResponse<string>>(
			'auth/reset-password',
			data,
		);
	}
}
