/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import {
	HttpInterceptor,
	HttpHandler,
	HttpRequest,
} from '@angular/common/http';
import { environment } from '@environment/environment';
import { AuthService } from '@core/services/auth.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

const SKIPPED_DOMAINS: string[] = ['storage.googleapis.com'];

@Injectable()
export class APIInterceptor implements HttpInterceptor {
	constructor(
		private _authService: AuthService,
		private _router: Router,
		private _snackBar: MatSnackBar,
	) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
		// Skip adding api url and error handling
		for (const domain of SKIPPED_DOMAINS) {
			if (req.url.includes(domain)) {
				return next.handle(req);
			}
		}

		// Add the api url to every request
		return next.handle(
			req.clone({
				url: `${environment.apiUrl}/${req.url}`,
				withCredentials: true,
			}),
		);
	}
}
