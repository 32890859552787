import { HttpParams } from '@angular/common/http';
import { BaseHttpFilters } from '@core/models/base/base.service';

const parseParams = (filters?: BaseHttpFilters): HttpParams => {
	let params = new HttpParams();

	if (filters) {
		for (const [param, value] of Object.entries(filters)) {
			// Value can be false and it won't be added so check explicitly
			if (value !== null && value !== '' && typeof value !== 'undefined') {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
				params = params.set(param, value.toString());
			}
		}
	}

	return params;
};

export default parseParams;
