import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(localizedFormat);

interface Timezone {
	name: string;
	offset: string;
}

@Component({
	selector: 'fire-timezone-select',
	templateUrl: './timezone-select.component.html',
})
export class TimezoneSelectComponent {
	@Input()
	fGroup: FormGroup;

	@Input()
	fControlName = 'timezoneName';

	@Input()
	label = 'Timezone';

	@Input()
	showDetect = true;

	filteredTimezones: Timezone[];

	readonly searchTerm = new FormControl('');

	readonly timezones: Timezone[] = // @ts-ignore
		(Intl.supportedValuesOf('timeZone') as string[]).map((tz) => {
			const offset = TimezoneSelectComponent.getOffset(tz);

			return {
				name: tz,
				offset,
			};
		});

	get hasRequiredValidator(): boolean {
		return this.fGroup
			?.get(this.fControlName)
			?.hasValidator(Validators.required);
	}

	constructor() {
		this.searchTerm.valueChanges.pipe(debounceTime(200)).subscribe((value) => {
			this.filteredTimezones = this.timezones.filter((tz) => {
				return (
					tz.name.toLowerCase().includes(value.toLowerCase()) ||
					tz.offset.toLowerCase().includes(value.toLowerCase())
				);
			});
		});

		this.filteredTimezones = this.timezones;
	}

	detectTimezone(e: Event): void {
		e.stopPropagation();

		this.fGroup.get(this.fControlName).setValue(dayjs.tz.guess());
	}

	getDisplayName(tz: string): string {
		return tz.replaceAll('_', ' ');
	}

	static getOffset(timezoneName: string): string {
		if (!timezoneName) {
			return;
		}

		const timezoneOffset = dayjs().tz(timezoneName).utcOffset();

		const offset = Math.abs(timezoneOffset);

		const offsetOperator = timezoneOffset > 0 ? '+' : '-';

		const offsetHours = (offset / 60).toString().padStart(2, '0');

		const offsetMinutes = (offset % 60).toString().padStart(2, '0');

		return `${offsetOperator}${offsetHours.split('.')[0]}:${offsetMinutes}`;
	}
}
