import { EnvironmentEnum } from '@core/models/enums/environment';
import { Environment } from './environment.interface';

export const environment: Environment = {
	production: false,
	environment: EnvironmentEnum.Staging,
	version: '2.0.0',
	baseApiUrl: 'https://staging.api.fireballserver.com/api',
	assetsUrl: 'https://staging.app.fireballserver.com/',
	apiVersion: 'v2.0',
	sentryDSN:
		'https://99e17da57a2244f285ee86c069e78967@o4504249005047808.ingest.sentry.io/4504249023201280',
	githubAppUrl: 'https://github.com/apps/fireball-server',
	get githubInstallationUrl(): string {
		return `${this.githubAppUrl as string}/installations/new`;
	},
	get apiUrl(): string {
		return `${this.baseApiUrl as string}/${this.apiVersion as string}`;
	},
};
