import { Component } from '@angular/core';
import { environment } from '@environment/environment';

@Component({
	selector: 'fire-footer',
	styleUrls: ['./footer.component.scss'],
	templateUrl: './footer.component.html',
})
export class FooterComponent {
	version = environment.version;

	readonly currentYear: number;

	constructor() {
		this.currentYear = new Date().getFullYear();
	}
}
